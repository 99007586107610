<template>
  <div style="margin-bottom: 100px">
    <div class="d-flex align-items-center justify-content-between">
      <h4 class="mb-0">ไฟแนนซ์ทั้งหมด</h4>
      <button-loading-widget
        text="เพิ่มไฟแนนซ์"
        variant="primary"
        size="md"
        :loading="pageLoading"
        @click="onClickAdd"
      />
    </div>
    <hr />
    <b-card
      v-for="(finance, index) in financeList"
      :key="finance.id"
    >
      <b-overlay :show="finance.id === financeLoadingId">
        <div class="d-flex align-items-center">
          <b-card-title
            v-if="financeEditingId !== finance.id"
            class="mb-0 mr-50"
            :style="{ color: finance.finance_color }"
          >
            {{ finance.finance_name }} <small>( {{ finance.finance_code }} )</small>
          </b-card-title>
          <div
            v-else
            class="d-flex flex-column flex-sm-row mr-50"
          >
            <b-input-group
              prepend="ชื่อไฟแนนซ์"
              size="sm"
              class="mb-50 mb-sm-0 mr-sm-50"
            >
              <b-form-input
                v-model="finance.finance_name"
                placeholder="โปรดระบุ"
                class="text-center"
              />
            </b-input-group>
            <b-input-group
              prepend="รหัสไฟแนนซ์"
              size="sm"
            >
              <b-form-input
                v-model="finance.finance_code"
                placeholder="โปรดระบุ"
                class="text-center"
              />
            </b-input-group>
          </div>
          <!-- color picker -->
          <color-picker
            v-if="financeEditingId === finance.id"
            v-model="finance.finance_color"
            :position="{ left: 0, top: '40px' }"
            class="custom"
          />
        </div>
        <hr class="mt-50" />

        <b-row>
          <b-col
            sm="12"
            md="4"
          >
            <b-card-text>Size S (ดอกเบี้ย) </b-card-text>
            <div class="ml-1">
              <div
                v-for="size in finance.finance_interest_rate_datas.s"
                :key="size.interest_rate_period"
                class="mb-1"
              >
                <b-input-group
                  :prepend="`${size.interest_rate_period} เดือน`"
                  size="sm"
                  append="%"
                >
                  <b-form-input
                    v-model="size.interest_rate"
                    placeholder="0.000"
                    class="text-center"
                    :disabled="financeEditingId !== finance.id"
                    @blur="onInputBlur($event, v => (size.interest_rate = v))"
                  />
                </b-input-group>
              </div>
              <b-button
                v-if="financeEditingId === finance.id && finance.finance_interest_rate_datas.s.length > 1"
                size="sm"
                block
                variant="flat-danger"
                @click="deleteLocalRateConfig(finance, 's', index)"
                >ลบ</b-button
              >
              <b-button
                v-if="financeEditingId === finance.id"
                size="sm"
                block
                variant="flat-primary"
                @click="addLocalRateConfig(finance, 's', index)"
                >เพิ่ม</b-button
              >
            </div>
          </b-col>

          <!-- m -->
          <b-col
            sm="12"
            md="4"
          >
            <b-card-text>Size M (ดอกเบี้ย)</b-card-text>
            <div class="ml-1">
              <div
                v-for="size in finance.finance_interest_rate_datas.m"
                :key="size.interest_rate_period"
              >
                <b-input-group
                  v-if="!size.action"
                  :prepend="`${size.interest_rate_period} เดือน`"
                  size="sm"
                  append="%"
                  class="mb-1"
                >
                  <b-form-input
                    v-model="size.interest_rate"
                    placeholder="0.000"
                    class="text-center"
                    :disabled="financeEditingId !== finance.id"
                    @blur="onInputBlur($event, v => (size.interest_rate = v))"
                  />
                </b-input-group>
              </div>
              <b-button
                v-if="financeEditingId === finance.id && finance.finance_interest_rate_datas.m.length > 1"
                size="sm"
                block
                variant="flat-danger"
                @click="deleteLocalRateConfig(finance, 'm', index)"
                >ลบ</b-button
              >
              <b-button
                v-if="financeEditingId === finance.id"
                size="sm"
                block
                variant="flat-primary"
                @click="addLocalRateConfig(finance, 'm', index)"
                >เพิ่ม</b-button
              >
            </div>
          </b-col>

          <!-- l -->
          <b-col
            sm="12"
            md="4"
          >
            <b-card-text>Size L (ดอกเบี้ย)</b-card-text>
            <div class="ml-1">
              <b-input-group
                v-for="size in finance.finance_interest_rate_datas.l"
                :key="size.interest_rate_period"
                :prepend="`ดาวน์ ${size.interest_rate_period}%`"
                size="sm"
                append="%"
                class="mb-1"
              >
                <b-form-input
                  v-model="size.interest_rate"
                  placeholder="0.000"
                  class="text-center"
                  :disabled="financeEditingId !== finance.id"
                  @blur="onInputBlur($event, v => (size.interest_rate = v))"
                />
              </b-input-group>
              <b-button
                v-if="financeEditingId === finance.id && finance.finance_interest_rate_datas.l.length > 1"
                size="sm"
                block
                variant="flat-danger"
                @click="deleteLocalRateConfig(finance, 'l', index)"
                >ลบ</b-button
              >
              <b-button
                v-if="financeEditingId === finance.id"
                size="sm"
                block
                variant="flat-primary"
                @click="addLocalRateConfig(finance, 'l', index)"
                >เพิ่ม</b-button
              >
            </div>
          </b-col>
        </b-row>

        <b-form-group
          label="กฎการปัดเศษแต่ละเดือน"
          class="mt-1"
        >
          <v-select
            v-model="finance.rule_round"
            class="ml-1 mt-50"
            :options="optionsRuleRound"
            :disabled="financeEditingId !== finance.id"
            :clearable="false"
            :get-option-label="op => op.name"
          />
        </b-form-group>

        <b-card-text class="mt-2">ช่วงระหว่างเดือนที่ต้องการแสดง</b-card-text>
        <div class="px-2 pb-2">
          <vue-slider
            :value="[finance.month_range_start, finance.month_range_end]"
            :data="finance.marksData"
            :marks="true"
            :enable-cross="false"
            :disabled="financeEditingId !== finance.id"
            @change="value => onSliderChange(value, index)"
          />
        </div>

        <!-- button edit delete -->
        <div class="mt-2">
          <div
            v-if="!financeEditingId"
            class="d-flex justify-content-end"
          >
            <b-button
              class="mr-50"
              variant="primary"
              @click="onClickEdit(finance)"
              >ตั้งค่า</b-button
            >
            <b-button
              variant="danger"
              @click="onClickDelete(finance)"
              >ลบ</b-button
            >
          </div>
          <div
            v-if="financeEditingId === finance.id"
            class="d-flex justify-content-end"
          >
            <b-button
              variant="success"
              class="mr-50"
              @click="onClickSave(finance)"
              >บันทึก</b-button
            >
            <b-button
              variant="flat-secondary"
              @click="onClickCancel(finance)"
              >ยกเลิก</b-button
            >
          </div>
        </div>
      </b-overlay>
      <!-- <pre>{{ finance.month_range_start }} {{ finance.month_range_end }}</pre> -->
      <!-- <pre>{{ draftDeleteList }}</pre> -->
      <!-- <pre>{{ finance.rule_round }}</pre> -->
    </b-card>

    <!-- modal -->
    <b-modal
      ref="refModalAddFinance"
      title="เพิ่มไฟแนนซ์"
      ok-title="เพิ่ม"
      ok-variant="success"
      cancel-variant="flat-secondary"
      cancel-title="ยกเลิก"
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      @ok="confirmAddFinance"
      @cancel="resetFormFinance"
    >
      <validation-observer ref="refFormFinance">
        <b-form-group label="ชื่อไฟแนนซ์">
          <validation-provider
            #default="{ errors }"
            name="ชื่อไฟแนนซ์"
            rules="required"
          >
            <b-form-input
              v-model="formFinance.finance_name"
              :state="errors.length > 0 ? false : null"
              placeholder="โปรดระบุ"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group label="รหัสไฟแนนซ์">
          <validation-provider
            #default="{ errors }"
            name="รหัสไฟแนนซ์"
            rules="required"
          >
            <b-form-input
              v-model="formFinance.finance_code"
              :state="errors.length > 0 ? false : null"
              placeholder="โปรดระบุ"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <div class="d-flex align-items-center mb-50">
          <p class="mr-50 mb-0">เลือกสี</p>
          <color-picker
            v-model="formFinance.finance_color"
            :position="{ left: 0, top: '40px' }"
            class="custom"
          />
        </div>

        <b-form-group label="กฎการปัดเศษแต่ละเดือน">
          <validation-provider
            #default="{ errors }"
            name="กฎการปัดเศษแต่ละเดือน"
            rules="required"
          >
            <v-select
              v-model="formFinance.rule_round"
              :options="optionsRuleRound"
              :class="[errors[0] ? 'error' : null]"
              :clearable="false"
              :get-option-label="op => op.name"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- size s -->
        <b-row class="mt-1">
          <b-col>
            <b-form-group label="เดือนเริ่มต้นไซส์ S">
              <validation-provider
                #default="{ errors }"
                name="เดือนเริ่มต้นไซส์ S"
                rules="required"
              >
                <b-form-input
                  v-model="formFinance.interest_datas.s.interest_rate_period_start"
                  :state="errors.length > 0 ? false : null"
                  type="number"
                  number
                  placeholder="โปรดระบุ"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="เดือนสิ้นสุดไซส์ S">
              <validation-provider
                #default="{ errors }"
                name="เดือนเริ่มต้นไซส์ S"
                rules="required"
              >
                <b-form-input
                  v-model="formFinance.interest_datas.s.interest_rate_period_end"
                  :state="errors.length > 0 ? false : null"
                  type="number"
                  number
                  placeholder="โปรดระบุ"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="12">
            <b-form-group label="จำนวนนับเพิ่มไซส์ S">
              <validation-provider
                #default="{ errors }"
                name="จำนวนนับเพิ่มไซส์ S"
                rules="required|min_value:6"
              >
                <b-form-input
                  v-model="formFinance.interest_datas.s.interest_rate_step"
                  :state="errors.length > 0 ? false : null"
                  type="number"
                  number
                  placeholder="โปรดระบุ"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- size m -->
        <b-row class="mt-50">
          <b-col>
            <b-form-group label="เดือนเริ่มต้นไซส์ M">
              <validation-provider
                #default="{ errors }"
                name="เดือนเริ่มต้นไซส์ M"
                rules="required"
              >
                <b-form-input
                  v-model="formFinance.interest_datas.m.interest_rate_period_start"
                  :state="errors.length > 0 ? false : null"
                  type="number"
                  number
                  placeholder="โปรดระบุ"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="เดือนสิ้นสุดไซส์ M">
              <validation-provider
                #default="{ errors }"
                name="เดือนสิ้นสุดไซส์ M"
                rules="required"
              >
                <b-form-input
                  v-model="formFinance.interest_datas.m.interest_rate_period_end"
                  :state="errors.length > 0 ? false : null"
                  type="number"
                  number
                  placeholder="โปรดระบุ"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <b-form-group label="จำนวนนับเพิ่มไซส์ M">
              <validation-provider
                #default="{ errors }"
                name="จำนวนนับเพิ่มไซส์ M"
                rules="required|min_value:6"
              >
                <b-form-input
                  v-model="formFinance.interest_datas.m.interest_rate_step"
                  :state="errors.length > 0 ? false : null"
                  type="number"
                  number
                  placeholder="โปรดระบุ"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- size l -->
        <b-row class="mt-50">
          <b-col>
            <b-form-group label="เปอร์เซ็นนดาวน์เริ่มต้นไซส์ L">
              <validation-provider
                #default="{ errors }"
                name="เปอร์เซ็นนดาวน์เริ่มต้นไซส์ L"
                rules="required|max_value:100"
              >
                <b-form-input
                  v-model="formFinance.interest_datas.l.interest_rate_period_start"
                  :state="errors.length > 0 ? false : null"
                  type="number"
                  number
                  placeholder="โปรดระบุ"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="เปอร์เซ็นนดาวน์สิ้นสุดไซส์ L">
              <validation-provider
                #default="{ errors }"
                name="เปอร์เซ็นนดาวน์สิ้นสุดไซส์ L"
                rules="required|max_value:100"
              >
                <b-form-input
                  v-model="formFinance.interest_datas.l.interest_rate_period_end"
                  :state="errors.length > 0 ? false : null"
                  type="number"
                  number
                  placeholder="โปรดระบุ"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <b-form-group label="จำนวนนับเพิ่มไซส์ L">
              <validation-provider
                #default="{ errors }"
                name="จำนวนนับเพิ่มไซส์ L"
                rules="required|min_value:5"
              >
                <b-form-input
                  v-model="formFinance.interest_datas.l.interest_rate_step"
                  :state="errors.length > 0 ? false : null"
                  type="number"
                  number
                  placeholder="โปรดระบุ"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { required, min_value, max_value } from '@validations'

export default {
  components: {
    VueSlider,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  data() {
    return {
      financeList: [],
      rawFinanceList: [],
      marksData: [],
      financeEditingId: null,
      financeLoadingId: null,
      pageLoading: false,
      formFinance: {
        finance_name: '',
        finance_code: '',
        finance_color: '#5d5fef',
        interest_datas: {
          s: { interest_rate_period_start: 12, interest_rate_period_end: 60, interest_rate_step: 6 },
          m: { interest_rate_period_start: 12, interest_rate_period_end: 60, interest_rate_step: 6 },
          l: { interest_rate_period_start: 0, interest_rate_period_end: 20, interest_rate_step: 5 },
        },
        rule_round: { key: 'round_tens_up_nd', name: 'ปัดเศษหลัก 10 ไม่เอาทศนิยม' },
      },
      draftDeleteList: {
        s: [],
        m: [],
        l: [],
      },
      // 'round_tens_up_nd' ปัดเศษหลัก 10 ขึ้น ไม่เอาทศนิยม || 'round_up_nd' ปัดขึ้นไม่เอาทศนิยม || 'round_down_nd' ปัดลงไม่เอาทศนิยม
      optionsRuleRound: [
        { key: 'round_tens_up_nd', name: 'ปัดเศษหลัก 10 ขึ้น ไม่เอาทศนิยม' },
        { key: 'round_up_nd', name: 'ปัดขึ้น ไม่เอาทศนิยม' },
        { key: 'round_down_nd', name: 'ปัดลง ไม่เอาทศนิยม' },
      ],
    }
  },
  created() {
    this.getFinanceList()
  },
  methods: {
    onSliderChange(value, financeIndex) {
      if (this.financeList[financeIndex].marksData.length === 1) return
      const [start, end] = value
      this.financeList[financeIndex].month_range_start = start
      this.financeList[financeIndex].month_range_end = end
    },
    onInputBlur(event, changeValue = () => {}) {
      const { value } = event.target
      const n = Number(value)

      if (!n) {
        changeValue('0.000')
        return
      }

      if (n > 100) {
        changeValue('100.000')
        return
      }

      changeValue(n.toFixed(3))
    },
    onClickEdit(finance) {
      // console.log('onClickEdit', finance)
      this.financeEditingId = finance.id
    },
    onClickCancel(finance) {
      this.financeList = JSON.parse(JSON.stringify([...this.rawFinanceList]))
      this.financeEditingId = null
      this.draftDeleteList = JSON.parse(
        JSON.stringify({
          s: [],
          m: [],
          l: [],
        }),
      )
      // const {default_month_range_start,default_month_range_end} = finance
      // this.financeList
    },
    async onClickSave(finance) {
      if (!finance.finance_name || !finance.finance_code) {
        this.gDisplayToast('เช็คความถูกต้องอีกครั้ง', 'ชื่อไฟแนนซ์และรหัสไฟแนนซ์ จำเป็นต้องระบุ', 'danger')
        return
      }

      const check = await this.gCheckConfirmV2(
        `ยืนยันแก้ไขข้อมูลไฟแนนซ์ ${finance.finance_name} (${finance.finance_code})`,
      )
      if (!check) return

      const listSizeM = [...finance.finance_interest_rate_datas.m, ...this.draftDeleteList.m]
      const listSizeS = [...finance.finance_interest_rate_datas.s, ...this.draftDeleteList.s]
      const listSizeL = [...finance.finance_interest_rate_datas.l, ...this.draftDeleteList.l]

      // if (listSizeS.some(v => Number(v.interest_rate) <= 0)) {
      //   this.gDisplayToast('โปรดตรวจสอบข้อมูล', 'ดอกเบี้ย Size S ทั้งหมดต้องไม่เท่ากับ 0', 'danger')
      //   return
      // }

      // if (listSizeM.some(v => Number(v.interest_rate) <= 0)) {
      //   this.gDisplayToast('โปรดตรวจสอบข้อมูล', 'ดอกเบี้ย Size M ทั้งหมดต้องไม่เท่ากับ 0', 'danger')
      //   return
      // }

      if (listSizeL.some(v => Number(v.interest_rate) <= 0)) {
        this.gDisplayToast('โปรดตรวจสอบข้อมูล', 'ดอกเบี้ย Size L ทั้งหมดต้องไม่เท่ากับ 0', 'danger')
        return
      }

      const requestData = {
        ...finance,
        finance_interest_rate_datas: {
          s: listSizeS,
          m: listSizeM,
          l: listSizeL,
        },
      }

      this.financeLoadingId = finance.id
      const resp = await this.$http.put(`api/finance-data/${finance.id}`, requestData).catch(() => null)
      // console.log('onClickSave', resp)

      if (resp && resp.code === 200) {
        // this.rawFinanceList = JSON.parse(JSON.stringify([...resp.data]))
        // this.financeList = JSON.parse(JSON.stringify([...resp.data]))
        await this.getFinanceList()
      } else {
        this.financeList = JSON.parse(JSON.stringify(this.rawFinanceList))
      }
      this.draftDeleteList = JSON.parse(
        JSON.stringify({
          s: [],
          m: [],
          l: [],
        }),
      )
      this.financeLoadingId = null
      this.financeEditingId = null
    },
    async onClickDelete(finance) {
      const check = await this.gCheckConfirmV2(
        `ยืนยันลบข้อมูลไฟแนนซ์ ${finance.finance_name} (${finance.finance_code})`,
      )
      if (!check) return
      this.financeLoadingId = finance.id
      const resp = await this.$http.delete(`api/finance-data/${finance.id}`).catch(() => null)
      // console.log('onClickDelete', resp)
      if (resp && resp.code === 200) {
        const newList = [...this.financeList].filter(v => v.id !== finance.id)
        this.financeList = JSON.parse(JSON.stringify([...newList]))
        this.rawFinanceList = JSON.parse(JSON.stringify([...newList]))
      }
      this.financeLoadingId = null
    },
    async getFinanceList() {
      // const mockData = [
      //   {
      //     id: '1',
      //     name: 'SOMJAI',
      //     code: 'FNUMBER1',
      //     month_range_start: 12,
      //     month_range_end: 60,
      //     table_color_code: '#5d5fef',
      //     size_s: [
      //       { month_amount: 12, interest: '0.000' },
      //       { month_amount: 18, interest: '0.000' },
      //       { month_amount: 24, interest: '0.000' },
      //       { month_amount: 30, interest: '0.000' },
      //       { month_amount: 36, interest: '0.000' },
      //       { month_amount: 42, interest: '0.000' },
      //       { month_amount: 48, interest: '0.000' },
      //       { month_amount: 54, interest: '0.000' },
      //       { month_amount: 60, interest: '0.000' },
      //     ],
      //     size_m: [
      //       { month_amount: 12, interest: '0.000' },
      //       { month_amount: 18, interest: '0.000' },
      //       { month_amount: 24, interest: '0.000' },
      //       { month_amount: 30, interest: '0.000' },
      //       { month_amount: 36, interest: '0.000' },
      //       { month_amount: 42, interest: '0.000' },
      //       { month_amount: 48, interest: '0.000' },
      //       { month_amount: 54, interest: '0.000' },
      //       { month_amount: 60, interest: '0.000' },
      //     ],
      //     size_l: [
      //       { percent_down: 0, interest: '0.000' },
      //       { percent_down: 5, interest: '0.000' },
      //       { percent_down: 10, interest: '0.000' },
      //       { percent_down: 15, interest: '0.000' },
      //       { percent_down: 20, interest: '0.000' },
      //     ],
      //   },
      //   {
      //     id: '2',
      //     name: 'S11',
      //     code: 'FNUMBER2',
      //     month_range_start: 12,
      //     month_range_end: 60,
      //     table_color_code: '#5d5fef',
      //     size_s: [
      //       { month_amount: 12, interest: '0.000' },
      //       { month_amount: 18, interest: '0.000' },
      //       { month_amount: 24, interest: '0.000' },
      //       { month_amount: 30, interest: '0.000' },
      //       { month_amount: 36, interest: '0.000' },
      //       { month_amount: 42, interest: '0.000' },
      //       { month_amount: 48, interest: '0.000' },
      //       { month_amount: 54, interest: '0.000' },
      //       { month_amount: 60, interest: '0.000' },
      //     ],
      //     size_m: [
      //       { month_amount: 12, interest: '0.000' },
      //       { month_amount: 18, interest: '0.000' },
      //       { month_amount: 24, interest: '0.000' },
      //       { month_amount: 30, interest: '0.000' },
      //       { month_amount: 36, interest: '0.000' },
      //       { month_amount: 42, interest: '0.000' },
      //       { month_amount: 48, interest: '0.000' },
      //       { month_amount: 54, interest: '0.000' },
      //       { month_amount: 60, interest: '0.000' },
      //     ],
      //     size_l: [
      //       { percent_down: 0, interest: '0.000' },
      //       { percent_down: 5, interest: '0.000' },
      //       { percent_down: 10, interest: '0.000' },
      //       { percent_down: 15, interest: '0.000' },
      //       { percent_down: 20, interest: '0.000' },
      //     ],
      //   },
      //   {
      //     id: '3',
      //     name: 'CATHAY',
      //     code: 'FNUMBER3',
      //     month_range_start: 12,
      //     month_range_end: 60,
      //     table_color_code: '#5d5fef',
      //     size_s: [
      //       { month_amount: 12, interest: '0.000' },
      //       { month_amount: 18, interest: '0.000' },
      //       { month_amount: 24, interest: '0.000' },
      //       { month_amount: 30, interest: '0.000' },
      //       { month_amount: 36, interest: '0.000' },
      //       { month_amount: 42, interest: '0.000' },
      //       { month_amount: 48, interest: '0.000' },
      //       { month_amount: 54, interest: '0.000' },
      //       { month_amount: 60, interest: '0.000' },
      //     ],
      //     size_m: [
      //       { month_amount: 12, interest: '0.000' },
      //       { month_amount: 18, interest: '0.000' },
      //       { month_amount: 24, interest: '0.000' },
      //       { month_amount: 30, interest: '0.000' },
      //       { month_amount: 36, interest: '0.000' },
      //       { month_amount: 42, interest: '0.000' },
      //       { month_amount: 48, interest: '0.000' },
      //       { month_amount: 54, interest: '0.000' },
      //       { month_amount: 60, interest: '0.000' },
      //     ],
      //     size_l: [
      //       { percent_down: 0, interest: '0.000' },
      //       { percent_down: 5, interest: '0.000' },
      //       { percent_down: 10, interest: '0.000' },
      //       { percent_down: 15, interest: '0.000' },
      //       { percent_down: 20, interest: '0.000' },
      //     ],
      //   },
      // ]
      this.pageLoading = true
      const { code, data } = await this.$http.get('api/finance-data').catch(() => null)
      // console.log('getFinanceList', data)
      if (code === 200) {
        const newList = [...data].map(v => ({
          ...v,
          marksData: this.generateMarksData(v.default_month_range_start, v.default_month_range_end, 6),
        }))
        this.financeList = [...newList]
        this.rawFinanceList = JSON.parse(JSON.stringify([...newList]))
      }
      this.pageLoading = false
    },
    async onClickAdd() {
      this.$refs.refModalAddFinance.show()
    },
    async confirmAddFinance(bvModalEvt) {
      bvModalEvt.preventDefault()
      const validate = await this.$refs.refFormFinance.validate()
      if (!validate) return
      this.$refs.refModalAddFinance.hide()
      // console.log('confirmAddFinance', this.formFinance)
      // return
      this.pageLoading = true
      const resp = await this.$http.post('api/finance-data', this.formFinance).catch(() => null)
      // console.log('confirmAddFinance', resp)
      if (resp && resp.code === 200) {
        await this.getFinanceList()
      }
      this.resetFormFinance()
      this.pageLoading = false
    },
    resetFormFinance() {
      this.formFinance = {
        finance_name: '',
        finance_code: '',
        finance_color: '#5d5fef',
        interest_datas: {
          s: { interest_rate_period_start: 12, interest_rate_period_end: 60, interest_rate_step: 6 },
          m: { interest_rate_period_start: 12, interest_rate_period_end: 60, interest_rate_step: 6 },
          l: { interest_rate_period_start: 0, interest_rate_period_end: 20, interest_rate_step: 5 },
        },
        rule_round: { key: 'round_tens_up_nd', name: 'ปัดเศษหลัก 10 ไม่เอาทศนิยม' },
      }
    },
    addLocalRateConfig(finance, keySize, financeIndex) {
      const lastLength = finance.finance_interest_rate_datas[keySize].length
      const lastData = JSON.parse(JSON.stringify(finance.finance_interest_rate_datas[keySize][lastLength - 1]))
      // console.log('t', { lastLength, lastData })

      const deleteOldDataIndex = this.draftDeleteList[keySize].findIndex(
        v => v.interest_rate_period === lastData.interest_rate_period + lastData.interest_rate_step,
      )
      if (deleteOldDataIndex >= 0) {
        const newObj = JSON.parse(JSON.stringify(this.draftDeleteList[keySize][deleteOldDataIndex]))
        // console.log('new', newObj)
        const { action, ...res } = newObj
        this.financeList[financeIndex].finance_interest_rate_datas[keySize].push({ ...res })
        this.draftDeleteList[keySize] = this.draftDeleteList[keySize].slice(0, deleteOldDataIndex)
      } else {
        const newObj = {
          ...lastData,
          interest_rate_period: lastData.interest_rate_period + lastData.interest_rate_step,
          id: '',
          interest_rate: '0.000',
        }
        this.financeList[financeIndex].finance_interest_rate_datas[keySize].push({ ...newObj })
      }

      if (keySize === 'l') return
      const { s, m } = finance.finance_interest_rate_datas
      const minS = s[0].interest_rate_period
      const minM = m[0].interest_rate_period

      const maxS = s[s.length - 1].interest_rate_period
      const maxM = m[m.length - 1].interest_rate_period

      const min = minS <= minM ? minS : minM
      const max = maxS >= maxM ? maxS : maxM
      // console.log({ min, max })

      this.financeList[financeIndex].marksData = this.generateMarksData(min, max, 6)
      // const { month_range_start } = finance
      // if (max >= month_range_start) return
      setTimeout(() => {
        this.financeList[financeIndex].month_range_start = min
        this.financeList[financeIndex].month_range_end = max
      }, 100)
    },
    generateMarksData(start, end, step = 6) {
      const list = []
      for (let index = start; index <= end; index += step) {
        const n = Number(index)
        list.push(n)
      }

      if (list.length === 1) {
        list.push(list[0])
      }

      return list
    },
    deleteLocalRateConfig(finance, keySize, financeIndex) {
      const lastLength = finance.finance_interest_rate_datas[keySize].length
      const lastData = JSON.parse(JSON.stringify(finance.finance_interest_rate_datas[keySize][lastLength - 1]))

      if (lastData.id) {
        this.draftDeleteList[keySize].push({ ...lastData, action: 'delete' })
      }

      this.financeList[financeIndex].finance_interest_rate_datas[keySize] = this.financeList[
        financeIndex
      ].finance_interest_rate_datas[keySize].slice(0, lastLength - 1)

      if (keySize === 'l') return

      const { s, m } = finance.finance_interest_rate_datas
      const minS = s[0].interest_rate_period
      const minM = m[0].interest_rate_period

      const maxS = s[s.length - 1].interest_rate_period
      const maxM = m[m.length - 1].interest_rate_period

      const min = minS <= minM ? minS : minM
      const max = maxS >= maxM ? maxS : maxM
      this.financeList[financeIndex].marksData = this.generateMarksData(min, max, 6)
      // const { month_range_end } = finance
      // if (max >= month_range_end) return
      setTimeout(() => {
        this.financeList[financeIndex].month_range_start = min
        this.financeList[financeIndex].month_range_end = max
      }, 100)
    },
  },
}
</script>

<style lang="scss">
.one-colorpicker.custom {
  .color-block-layer {
    border-radius: 100%;
  }
}
</style>
